import React from 'react';
import BlogEntry from './BlogEntry';

const BlogList: React.FC = () => {
  const blogs = [
    {
      title: 'Info zu Erdgas',
      text: 'Informationen zur Umweltschädlichkeit von Erdgas',
      date: '26. Februar 2025',
      pdfUrl:   `${process.env.PUBLIC_URL}/Erdgas.pdf`
    },
    {
      title: 'Einladung zur Infoveranstaltung',
      text: 'Wir laden alle Interessierten herzlich zu userer Infoveranstaltung am Mittwoch, 19. Februar, um 19:00 Uhr im Gasthof Rößle in Altusried ein.',
      date: '15. Februar 2025',
      pdfUrl:   `${process.env.PUBLIC_URL}/Info-Veranstaltung.pdf`
    },
    {
      title: 'Artikel im Altusrieder Blättle',
      text: 'Artikel im Altusrieder Blättle vom Dezember 2024',
      date: '1. Dezember 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/BlättleDez24.pdf`
    },
    {
      title: 'Artikel zur Wärmewende im Allgäu im Allgäuer Wirtschaftsmagazin 5/24',
      text: 'Artikel in der Oktoberausgabe des Allgäuer Wirtschaftsmagazins: "Im Allgäu unterstützt Holz die Wärmewende"',
      date: '1. Oktober 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/AWM_05_2024_Hauptheft_Oktober.pdf`
    },
    {
      title: 'Kommentar zum AZ Artikel',
      text: 'Kommentar zum Artikel in der Allgäuer Zeitung vom 24.09.2024 "Wärmenetz im Oberallgäu: Zu teuer - Folgen für Bürger" ',
      date: '28. September 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/Kommentar_AZ.pdf`
    },
    {
      title: 'Erfolgreicher Spatenstich',
      text: 'Der Spatenstich fand am Freitag, den 13. September 2024, statt und war ein voller Erfolg! Insgesamt waren 70 Personen anwesend, darunter knapp die Hälfte Genossenschaftsmitglieder. Es war beeindruckend zu sehen, wie viel Interesse und Unterstützung unsere Gemeinschaft für dieses wichtige Projekt zeigt. Die Redner des Tages umfassten namhafte Vertreter: Der stellvertretende Landrat Norbert Meggle aus Reicholzried, den Hauptplaner des Heizhauses Heribert Hartmann, sowie Dr. Eva Wirthensohn, die 2. Bürgermeisterin. Auch Hugo Wirthensohn hatte die Ehre, als BEGA-Vorstand zu sprechen. Wir blicken optimistisch in die Zukunft und freuen uns auf die weitere Entwicklung unseres Heizhauses!',
      date: '13. September 2024',
      imageUrls:[ 
        '/spatenstich/IMG-20240913-WA0016.jpg',
        '/spatenstich/img1.jpg',
        '/spatenstich/img2.jpg',
        '/spatenstich/img3.jpg',
        '/spatenstich/IMG-20240913-WA0017.jpg',
        '/spatenstich/IMG-20240913-WA0013.jpg'
      ],
    },
    {
      title: 'Einladung zum Spatenstich',
      text: 'Am Freitag, den 13. September 2024 findet um 11:00 Uhr der feierliche Spatenstich für das Heizhaus oberhalb des Tennisheims statt. Wir laden alle Mitglieder unserer Genossenschaft sowie Interessierte herzlich dazu ein.',
      date: '30. August 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/Einladung_Spatenstich.pdf`
    },
    {
      title: 'Vortrag Mitgliederversammlung 03.07.2024',
      text: 'Vortrag der Mitgliederversammlung vom 3. Juli 2024.',
      date: '17. März, 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/Infoversammlung_03_07_2024.pdf`
    },
    {
      title: 'Presseartikel Mitgliederversammlung 03.07.2024',
      text: 'Presseartikel über die Mitgliederversammlung vom 3. Juli 2024.',
      date: '03. Juli, 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/Presseartikel_03_07_2024.pdf`
    },
    {
      title: 'Protokoll Mitgliederversammlung 17.03.2024',
      text: 'Das Protokoll der Mitgliederversammlung vom 17. März 2024.',
      date: '17. März, 2024',
      pdfUrl:   `${process.env.PUBLIC_URL}/Protokoll_17_03_2024.pdf`
    },
    {
      title: 'Protokoll Mitgliederversammlung 09.10.2023',
      text: 'Das Protokoll der Mitgliederversammlung vom 9. Oktober 2023.',
      date: '9. Oktober, 2023',
      pdfUrl:   `${process.env.PUBLIC_URL}/Protokoll_09_10_2023.pdf`
    },
    {
      title: 'Protokoll Mitgliederversammlung 22.03.2023',
      text: 'Das Protokoll der Mitgliederversammlung vom 22. März 2023.',
      date: '22. März, 2023',
      pdfUrl:   `${process.env.PUBLIC_URL}/Protokoll_22_03_2023.pdf`
    }
  ];

  return (
    <div>
      {blogs.map((blog, index) => (
        <BlogEntry key={index} {...blog} />
      ))}
    </div>
  );
};


export default BlogList;
